import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import { colorThresholds } from '@/root/shared-consts'

interface ProgressBarProps extends HTMLAttributes<HTMLProgressElement> {
  value: number
  max: number
  hasTruck?: boolean
}

const { GREEN_PERCENT, YELLOW_PERCENT, MIN_PERCENT } = colorThresholds

export const ProgressBar: FC<ProgressBarProps> = ({ value, max, hasTruck = false, className, ...rest }) => {
  const safeMax = max > 0 ? max : 100
  const percent = Math.round((value / safeMax) * 100)
  const minThreshold = Math.round((MIN_PERCENT * safeMax) / 100)

  const isGreen = percent >= GREEN_PERCENT
  const isYellow = percent >= YELLOW_PERCENT && percent < GREEN_PERCENT
  const isRed = percent < YELLOW_PERCENT

  return (
    <progress
      className={cn(
        'h-1.5 bg-neutral-very-light rounded-sm [&::-moz-progress-bar]:rounded-sm [&::-webkit-progress-bar]:rounded-sm [&::-webkit-progress-bar]:bg-neutral-very-light [&::-webkit-progress-value]:rounded-sm',
        hasTruck ? 'progress-bar-large' : 'progress-bar-small',
        {
          '[&::-moz-progress-bar]:bg-success-medium-lighter [&::-webkit-progress-value]:bg-success-medium-lighter':
            isGreen,
          '[&::-moz-progress-bar]:bg-warning-medium-lighter [&::-webkit-progress-value]:bg-warning-medium-lighter':
            isYellow,
          '[&::-moz-progress-bar]:bg-error-medium-light [&::-webkit-progress-value]:bg-error-medium-light': isRed,
        },
        className
      )}
      value={percent > MIN_PERCENT ? value : minThreshold}
      max={safeMax}
      {...rest}
    />
  )
}
