import { Inventory, Locale } from '@/root/shared-types'

interface Sail {
  inventory?: Inventory
  sailRefId?: number
  locale?: Locale
}

export const getVesselTitleLocalized = ({ inventory, sailRefId, locale = 'en' }: Sail): string => {
  return (
    inventory?.passengerDecksAvailabilities?.[sailRefId ?? 0]?.vesselTitles?.[locale] ??
    inventory?.carDecksAvailabilities?.[sailRefId ?? 0]?.vesselTitles?.[locale] ??
    ''
  )
}

export const getVessel = ({ inventory, sailRefId }: Sail): string => {
  return (
    inventory?.passengerDecksAvailabilities?.[sailRefId ?? 0]?.vessel ??
    inventory?.carDecksAvailabilities?.[sailRefId ?? 0]?.vessel ??
    ''
  )
}

export const hasDifferentVessels = (inventory1: Inventory, inventory2: Inventory): boolean => {
  const getUniqueVessels = (inventory: Inventory): string[] => {
    const vesselSet = new Set<string>()
    const sections: (keyof Inventory)[] = ['carDecksAvailabilities', 'passengerDecksAvailabilities']

    sections.forEach((section) => {
      if (inventory[section] && typeof inventory[section] === 'object') {
        Object.values(inventory[section]).forEach((item) => {
          if (typeof item === 'object' && item?.vessel) {
            vesselSet.add(item.vessel)
          }
        })
      }
    })

    return Array.from(vesselSet)
  }

  const vesselsList1 = getUniqueVessels(inventory1)
  const vesselsList2 = getUniqueVessels(inventory2)

  if (vesselsList1.length === 0 || vesselsList2.length === 0) {
    return false
  }

  if (vesselsList1.length > 1 || vesselsList2.length > 1) {
    return true
  }

  return vesselsList1[0] !== vesselsList2[0]
}
