import { getReferenceTime } from '@/root/modules/shared/SelectDateTimePage'
import { Inventory, SailStatus } from '@/root/shared-types'
import { validStatuses } from '@/root/shared-consts'
import { DateTime } from 'luxon'
import * as R from 'ramda'

type TimestampAndStatusParams = {
  timestamp?: string
  status?: SailStatus
}

type HasFutureSailParams = {
  defaultTimezone: string
  inventory: Inventory
  initialTime?: DateTime
}

export const hasFutureSail = ({ defaultTimezone, inventory, initialTime }: HasFutureSailParams): boolean => {
  const referenceTime = getReferenceTime({
    defaultTimezone,
    initialTime,
  })

  const isFutureAndValidAvailability = (availability: TimestampAndStatusParams): boolean => {
    const { timestamp, status } = availability
    if (!status || !validStatuses.includes(status) || !timestamp) return false

    const parsedTimestamp = DateTime.fromISO(timestamp)
    return parsedTimestamp > referenceTime
  }

  const allAvailabilities = [
    ...Object.values(inventory.carDecksAvailabilities),
    ...Object.values(inventory.passengerDecksAvailabilities),
  ]

  return R.any(isFutureAndValidAvailability, allAvailabilities)
}
