import React, { FC, HTMLAttributes } from 'react'
import { CapacityTableColumns } from '@/root/shared-types'
import cn from 'classnames'
import { ClockIcon } from '@/root/ui/shared/icons/ClockIcon'
import { PassengerIconCyan } from '@/root/ui/shared/icons/PassengerIconCyan'
import { BicycleIcon } from '@/root/ui/shared/icons/BicycleIcon'
import { CarIcon } from '@/root/ui/shared/icons/CarIcon'
import { TruckIconCyan } from '@/root/ui/shared/icons/TruckIconCyan'

interface CapacityTableHeadProps extends HTMLAttributes<HTMLDivElement> {
  showColumns: CapacityTableColumns
  warningIconsCountInRow: number
}

export const CapacityTableHead: FC<CapacityTableHeadProps> = ({ showColumns, warningIconsCountInRow }) => {
  const { showPassengers, showBicycles, showCars, showTrucks } = showColumns

  return (
    <div data-testid="ticket-row-header" className="flex h-8 w-full items-center justify-between gap-1">
      <div className="flex xs:gap-1 sm:gap-2">
        <div className="ms-2.5 flex w-24 ps-9 xxs:w-28 sm:w-32">
          <ClockIcon ignoreRtl color="base-normal-lighter" width={17} />
        </div>
        <div
          className={cn({
            'w-5 sm:w-7': warningIconsCountInRow === 1,
            'w-12 sm:w-14': warningIconsCountInRow === 2,
            'w-16 sm:w-20': warningIconsCountInRow === 3,
          })}
        />
      </div>
      {showPassengers && (
        <div className="progress-bar-small flex justify-center">
          <PassengerIconCyan color="base-normal-lighter" width={16} className="w-3 xs:w-4" />
        </div>
      )}
      {showBicycles && (
        <div className="progress-bar-small flex justify-center">
          <BicycleIcon color="base-normal-lighter" width={36} className="w-7 xs:w-9" />
        </div>
      )}
      {showCars && (
        <div
          className={cn(
            'flex justify-center gap-1 xs:gap-1.5',
            showTrucks ? 'progress-bar-large' : 'progress-bar-small'
          )}
        >
          <CarIcon color="base-normal-lighter" width={40} className="w-7 xs:w-10" />
          {showTrucks && <TruckIconCyan color="base-normal-lighter" width={32} className="w-[22px] xs:w-8" />}
        </div>
      )}
      <div className="me-1 w-5 xxs:me-2 xxs:w-6 xs:me-4 md:w-24 lg:w-6 xl:w-24" />
    </div>
  )
}
