import * as R from 'ramda'
import { DAY_START_TIME } from '@/root/shared-consts/day-start-time'
import type { Inventory, LocalizedLocation, SailContent } from '@/root/shared-types'
import type { SailStatus } from '@/root/shared-types/selectDatePage/sails-types'
import type { CarDeckInventoryClass, DeckAvailability } from '@/root/shared-types/selectDatePage/inventory-types'
import { defaultInventory } from '@/root/shared-consts'
import { dateFormats } from '@/root/shared-consts'
import { DateTime } from 'luxon'

const { FULL_TIME_24, TIME_24 } = dateFormats

const parseDepartureTime = (time: string): DateTime => DateTime.fromFormat(time, TIME_24)
const dayStart = DateTime.fromFormat(DAY_START_TIME, FULL_TIME_24)

const mergeInventories = (inventory: Inventory, nextDayInventory: Inventory): Inventory => {
  return {
    departureDate: inventory.departureDate,
    carDecksAvailabilities: {
      ...inventory.carDecksAvailabilities,
      ...nextDayInventory.carDecksAvailabilities,
    },
    passengerDecksAvailabilities: {
      ...inventory.passengerDecksAvailabilities,
      ...nextDayInventory.passengerDecksAvailabilities,
    },
  }
}

type AdditionalProperties = { status: SailStatus; timestamp: string; cancellationReasons?: LocalizedLocation }

interface ModifiedInventoryParams {
  inventory: Inventory
  nextInventory: Inventory
  sailContent: SailContent[]
  nextSailContent: SailContent[]
}

export const getModifiedInventory = ({
  inventory,
  nextInventory,
  sailContent,
  nextSailContent,
}: ModifiedInventoryParams): Inventory => {
  if (!inventory || Object.keys(inventory).length === 0) {
    return defaultInventory
  }

  const voyages = sailContent.filter((voyage) => parseDepartureTime(voyage.departure.time) >= dayStart)
  const nextVoyages = nextSailContent.filter((voyage) => parseDepartureTime(voyage.departure.time) < dayStart)

  const allVoyages = [...voyages, ...nextVoyages]

  const mergedInventory = mergeInventories(inventory, nextInventory)

  const sailExtraDataMap = allVoyages.reduce<Record<string, AdditionalProperties>>(
    (acc, sail) => ({
      ...acc,
      [sail.sailRefId]: {
        status: sail.status,
        timestamp: sail.departure.timestamp,
        ...(sail.cancellationReasons && { cancellationReasons: sail.cancellationReasons }),
      },
    }),
    {}
  )

  const transform = ([key, availability]: [string, DeckAvailability<CarDeckInventoryClass>]) => {
    if (!sailExtraDataMap[availability.sailRefId]) return undefined

    const { status, timestamp, cancellationReasons } = sailExtraDataMap[availability.sailRefId]
    return [
      key,
      {
        ...availability,
        timestamp,
        status,
        ...(typeof cancellationReasons !== 'undefined' && { cancellationReasons }),
      },
    ]
  }

  const innerComposedApp = R.compose(Object.fromEntries, R.reject(R.isNil), R.map(transform), Object.entries)

  const carDecksAvailabilities = R.compose(innerComposedApp, R.prop('carDecksAvailabilities'))(mergedInventory)
  const passengerDecksAvailabilities = R.compose(
    innerComposedApp,
    R.prop('passengerDecksAvailabilities')
  )(mergedInventory)

  return {
    ...mergedInventory,
    carDecksAvailabilities,
    passengerDecksAvailabilities,
  }
}
