import { SailContent, TravelDuration } from '@/root/shared-types'
import { DateTime } from 'luxon'

interface TravelDurationParams {
  voyages: SailContent[]
  sailRefId: number
  nextVoyages?: SailContent[]
}

const getTimeFromVoyage = (timestamp?: string): DateTime => DateTime.fromISO(timestamp ?? '')

export const getTravelDuration = ({ voyages, sailRefId, nextVoyages = [] }: TravelDurationParams): TravelDuration => {
  const allVoyages = [...voyages, ...nextVoyages]
  const voyage = allVoyages.find((item) => item.sailRefId === sailRefId)

  if (!voyage) return { hours: 0, minutes: 0 }

  const departureTime = getTimeFromVoyage(voyage?.departure.timestamp)
  const arrivalTime = getTimeFromVoyage(voyage?.arrival.timestamp)

  if (!departureTime.isValid || !arrivalTime.isValid) return { hours: 0, minutes: 0 }

  const { hours = 0, minutes = 0 } = arrivalTime.diff(departureTime, ['hours', 'minutes']).toObject()

  return { hours: Math.floor(hours), minutes: Math.floor(minutes) }
}
