import { Dispatch } from 'react'
import { getReferenceTime } from '@/root/modules/shared/SelectDateTimePage'
import { SET_REF_ID_AND_TIME } from '@/root/modules/shared/SelectDateTimePage/state/actions'
import { DAY_START_TIME } from '@/root/shared-consts/day-start-time'
import { SelectDateTimeAction } from '@/root/modules/shared/SelectDateTimePage/state/types'
import { Direction, Inventory } from '@/root/shared-types'
import { dateFormats } from '@/root/shared-consts'
import { DateTime } from 'luxon'
import * as R from 'ramda'

const { FULL_TIME_24, ISO_DATE, SIMPLE_TIME } = dateFormats

interface SetTimeAndRefIdParams {
  defaultTimezone: string
  direction: Direction
  inventory: Inventory
  dispatch: Dispatch<SelectDateTimeAction>
  initialTime?: DateTime
}

const parseTimestamp = (timestamp?: string): DateTime | undefined =>
  timestamp ? DateTime.fromISO(timestamp) : undefined

export const setEarliestTimeAndRefId = ({
  defaultTimezone,
  direction,
  inventory,
  dispatch,
  initialTime,
}: SetTimeAndRefIdParams) => {
  if (!inventory || !inventory.departureDate) return

  const departureDate = DateTime.fromFormat(inventory.departureDate, ISO_DATE)

  const dayStartTime = DateTime.fromFormat(DAY_START_TIME, FULL_TIME_24)

  const maxTimestamp = departureDate.plus({ days: 1 }).set({
    hour: dayStartTime.hour,
    minute: dayStartTime.minute,
    second: dayStartTime.second,
  })

  const referenceTime = getReferenceTime({
    defaultTimezone,
    initialTime,
  })

  const allDecks = [
    ...Object.values(inventory.passengerDecksAvailabilities ?? {}),
    ...Object.values(inventory.carDecksAvailabilities ?? {}),
  ]

  let sailRefId = -1
  let earliestTime = maxTimestamp

  allDecks.forEach((deck) => {
    const timestamp = parseTimestamp(deck.timestamp)

    if (!timestamp?.isValid) return

    if (timestamp > referenceTime && timestamp < earliestTime && R.equals(deck.status, 'OPEN')) {
      earliestTime = timestamp
      sailRefId = deck.sailRefId
    }
  })

  const isBeforeMaxTimestamp = earliestTime < maxTimestamp

  const formattedTime = isBeforeMaxTimestamp ? earliestTime.toFormat(SIMPLE_TIME) : ''
  const isNextDay = isBeforeMaxTimestamp ? earliestTime.startOf('day') > departureDate.startOf('day') : false

  dispatch({
    type: SET_REF_ID_AND_TIME,
    payload: { direction, refIdAndTime: { sailRefId, time: formattedTime, isNextDay } },
  })
}
