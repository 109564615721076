import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

interface CapacityTableTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  title: string
  warningIconsInRow: number
}

export const CapacityTableTitle: FC<CapacityTableTitleProps> = ({ title, warningIconsInRow, ...props }) => {
  return (
    <h2
      className={cn('text-base-normal-light font-normal normal-case', {
        'ms-20 sm:ms-24 md:ms-7 lg:ms-24 xl:ms-7': warningIconsInRow === 0,
        'ms-24 sm:ms-32 md:ms-12 lg:ms-32 xl:ms-12': warningIconsInRow === 1,
        'ms-32 sm:ms-36 md:ms-20 lg:ms-36 xl:ms-20': warningIconsInRow === 2,
        'ms-36 sm:ms-44 md:ms-28 lg:ms-44 xl:ms-28': warningIconsInRow === 3,
      })}
      {...props}
    >
      {title}
    </h2>
  )
}
