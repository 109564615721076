import { FC, HTMLAttributes } from 'react'
import { useAppConfig, useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import {
  CapacityTableHead,
  CapacityTableRow,
  CapacityTableTitle,
  checkDangerousGoods,
  getInventoryAsArray,
  hasBicycleAvailability,
  hasSoldOut,
  maxWarningIconsInRow,
} from '@/root/modules/shared/SelectDateTimePage'
import { CapacityTableColumns, Inventory } from '@/root/shared-types'
import { DangerGoodsIcon } from '@/root/ui/shared/icons/DangerGoodsIcon'
import { NoTrucksIcon } from '@/root/ui/shared/icons/NoTrucksIcon'
import { AnchorIcon } from '@/root/ui/shared/icons/AnchorIcon'
import { DateTime } from 'luxon'
import * as R from 'ramda'

interface AvailableCapacityTableProps extends HTMLAttributes<HTMLDivElement> {
  inventory: Inventory
  sailRefId: number
  setRefIdAndTime: (sailRefId: number, time: string, isNextDay: boolean) => void
  initialTimestamp?: DateTime
  routeAttributes?: Record<string, boolean | string>
}

type CountAndFirstCancellationReason = { countCancelledSails: number; firstCancellationReason: string }

export const AvailableCapacityTable: FC<AvailableCapacityTableProps> = ({
  inventory,
  sailRefId,
  setRefIdAndTime,
  initialTimestamp,
  routeAttributes,
}) => {
  const { getTranslation } = useGetTranslations()
  const { locale } = useCustomRouter()
  const { defaultTimezone } = useAppConfig()

  const showColumns: CapacityTableColumns = {
    showPassengers: Boolean(
      inventory?.passengerDecksAvailabilities && Object.keys(inventory.passengerDecksAvailabilities).length > 0
    ),
    showBicycles: inventory ? hasBicycleAvailability(inventory) : false,
    showCars: Boolean(inventory?.carDecksAvailabilities && Object.keys(inventory.carDecksAvailabilities).length > 0),

    showTrucks: false, // TODO: Find corresponding field in inventory
  }

  const { showCars } = showColumns
  const showVehiclePassengerNote = routeAttributes?.['VEHICLE_TICKET_TEXT_NOTE']

  const showDangerGoods = inventory ? checkDangerousGoods(inventory) : false
  const showNoTrucks = false // TODO: Get showNoTrucks from BE

  const warningIconsInRow = inventory ? maxWarningIconsInRow(inventory) : 0

  const showSoldOutWarning = inventory ? hasSoldOut(inventory) : false

  const soldOutWarning = R.pathOr(
    getTranslation('outOfStockPart1'),
    [`ONLINE_SOLD_OUT_INFO_PART_ONE_${locale.toUpperCase()}`],
    routeAttributes
  )

  const mergedData = getInventoryAsArray({ defaultTimezone, inventory, initialTime: initialTimestamp })

  const hasNextDay = mergedData.some((item) => item.isNextDay === true)

  const { countCancelledSails, firstCancellationReason } = mergedData.reduce<CountAndFirstCancellationReason>(
    (acc, item) => {
      const isCancelled = item.carDeck?.status === 'CANCELLED' || item.passengerDeck?.status === 'CANCELLED'

      if (isCancelled) {
        acc.countCancelledSails++
        if (!acc.firstCancellationReason) {
          acc.firstCancellationReason =
            item.carDeck?.cancellationReasons?.[locale] ?? item.passengerDeck?.cancellationReasons?.[locale] ?? ''
        }
      }
      return acc
    },
    { countCancelledSails: 0, firstCancellationReason: '' }
  )

  const showAnchor = countCancelledSails > 0
  const cancellationReason = countCancelledSails === 1 ? firstCancellationReason : getTranslation('noteAboutCancelled')

  return (
    <div
      data-testid="sail-select-table"
      className="flex w-full flex-col items-center gap-2.5 text-sm font-bold uppercase"
    >
      <CapacityTableTitle title={getTranslation('availableCapacity')} warningIconsInRow={warningIconsInRow} />

      <div className="flex w-full flex-col items-center gap-2.5">
        <CapacityTableHead showColumns={showColumns} warningIconsCountInRow={warningIconsInRow} />
        {mergedData.map((item) => (
          <CapacityTableRow
            key={item.sailRefId}
            isSelected={sailRefId === Number(item.sailRefId)}
            time={item.departureTime}
            isNextDay={item.isNextDay}
            carDeck={item.carDeck}
            passengerDeck={item.passengerDeck}
            showColumns={showColumns}
            onChange={() => {
              setRefIdAndTime(Number(item.sailRefId), item.departureTime, item.isNextDay)
            }}
            warningIconsCountInRow={warningIconsInRow}
          />
        ))}
      </div>

      {hasNextDay && (
        <p className="text-secondary ms-2.5 self-start pt-2.5 text-base normal-case">{getTranslation('nextDayDepartures')}</p>
      )}

      {showDangerGoods && (
        <div className="text-hazard flex gap-5 self-start ps-2.5 pt-5 ">
          <div className="shrink-0">
            <DangerGoodsIcon width={25} data-testid="danger-goods-icon" />
          </div>
          <p>{getTranslation('dangerousGoods')}</p>
        </div>
      )}

      {showNoTrucks && (
        <div className="flex gap-5 self-start ps-2.5 pt-5 font-normal">
          <div className="shrink-0">
            <NoTrucksIcon width={25} />
          </div>
          <p>{getTranslation('noHeavyTracks')}</p>
        </div>
      )}

      {showAnchor && (
        <div className="text-error-dark flex gap-5 self-start ps-2.5 pt-5 leading-6">
          <div className="shrink-0">
            <AnchorIcon width={23} />
          </div>
          <p>{cancellationReason}</p>
        </div>
      )}

      {showCars && showVehiclePassengerNote && (
        <p className="text-primary-base px-2.5 pt-5 text-justify font-bold normal-case leading-6">
          {getTranslation('clueAdmVehicleAndPassenger')}
        </p>
      )}

      {showSoldOutWarning && (
        <div className="text-error-medium-light self-start ps-2.5 pt-5">
          <span className="text-large">* </span>
          <span>{soldOutWarning}</span>
        </div>
      )}
    </div>
  )
}
