import React, { Dispatch, FC, HTMLAttributes, SetStateAction } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { ReturnSailSepIcon } from '@/root/ui/shared/icons/ReturnSailSepIcon'

interface AddReturnRouteButtonProps extends HTMLAttributes<HTMLButtonElement> {
  setHideRoundTripButton: Dispatch<SetStateAction<boolean>>
}

export const AddReturnRouteButton: FC<AddReturnRouteButtonProps> = ({ setHideRoundTripButton }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <button
      className="bg-secondary-lightest border-secondary text-secondary flex w-full flex-col items-center rounded-md border p-5 text-large font-bold uppercase"
      onClick={() => setHideRoundTripButton(true)}
    >
      <span className="text-5xl font-normal leading-8">+</span>
      <span>{getTranslation('addReturnRouteLabel')}</span>
      <ReturnSailSepIcon width={46} height={36} color="secondary" />
    </button>
  )
}
