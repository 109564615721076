import { SelectDateTimeAction, SelectDateTimeState } from './types'
import {
  SET_DATE,
  SET_PORTS,
  SET_HIDE_ROUND_TRIP_BUTTON,
  SET_AVAILABLE_DATES,
  SET_INVENTORY,
  SET_SAIL_CONTENT,
  SET_LOADING,
  SET_REF_ID_AND_TIME,
  SET_NEXT_INVENTORY,
  SET_NEXT_SAIL_CONTENT,
} from './actions'
import { neverError } from '@/root/shared-providers/BookingCart/reducer'
import { Direction } from '@/root/shared-types'

const getDirKey = (direction: Direction) => (direction === 'to' ? 'there' : 'back')

export const selectDateTimeReducer = (
  state: SelectDateTimeState,
  action: SelectDateTimeAction
): SelectDateTimeState => {
  switch (action.type) {
    case SET_DATE: {
      const { direction, date } = action.payload
      const dirKey = getDirKey(direction)
      const backDate = state.sail.back.date

      const updatedSail =
        dirKey === 'there' && backDate && date > backDate
          ? {
              there: { ...state.sail.there, date },
              back: { ...state.sail.back, date },
            }
          : {
              ...state.sail,
              [dirKey]: { ...state.sail[dirKey], date },
            }
      return {
        ...state,
        sail: updatedSail,
      }
    }
    case SET_REF_ID_AND_TIME: {
      const {
        direction,
        refIdAndTime: { sailRefId, time, isNextDay },
      } = action.payload
      const dirKey = getDirKey(direction)
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: {
            ...state.sail[dirKey],
            sailRefId,
            time,
            isNextDay,
          },
        },
      }
    }
    case SET_AVAILABLE_DATES: {
      const { direction, availableDates } = action.payload
      const dirKey = getDirKey(direction)
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], availableDates },
        },
      }
    }
    case SET_PORTS: {
      const { from, to } = action.payload
      return {
        ...state,
        sail: {
          there: { ...state.sail.there, port: from },
          back: { ...state.sail.back, port: to },
        },
      }
    }
    case SET_INVENTORY: {
      const { direction, inventory } = action.payload
      const dirKey = getDirKey(direction)
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], inventory },
        },
      }
    }
    case SET_NEXT_INVENTORY: {
      const { direction, nextInventory } = action.payload
      const dirKey = getDirKey(direction)
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], nextInventory },
        },
      }
    }
    case SET_SAIL_CONTENT: {
      const { direction, sailContent } = action.payload
      const dirKey = getDirKey(direction)
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], sailContent },
        },
      }
    }
    case SET_NEXT_SAIL_CONTENT: {
      const { direction, nextSailContent } = action.payload
      const dirKey = getDirKey(direction)
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], nextSailContent },
        },
      }
    }
    case SET_LOADING: {
      const { direction, loading } = action.payload
      const dirKey = getDirKey(direction)
      return {
        ...state,
        sail: {
          ...state.sail,
          [dirKey]: { ...state.sail[dirKey], loading },
        },
      }
    }
    case SET_HIDE_ROUND_TRIP_BUTTON: {
      const hide = typeof action.payload === 'function' ? action.payload(state.hideRoundTripButton) : action.payload
      return {
        ...state,
        hideRoundTripButton: hide,
      }
    }
    default:
      neverError('Irrelevant type', action)
      return { ...state }
  }
}
