import { Inventory } from '@/root/shared-types'
import { DeckAvailability } from '@/root/shared-types/selectDatePage/inventory-types'
import * as R from 'ramda'

export const checkDangerousGoods = (inventory: Inventory): boolean => {
  const checkDangerousGoodsInDecks = <T>(decks: Record<string, DeckAvailability<T>>) =>
    Object.values(decks).some((deck) => deck.dangerousGoods === true)

  const hasDangerousGoodsInCarDecks = checkDangerousGoodsInDecks(inventory.carDecksAvailabilities)
  const hasDangerousGoodsInPassengerDecks = checkDangerousGoodsInDecks(inventory.passengerDecksAvailabilities)

  return hasDangerousGoodsInCarDecks || hasDangerousGoodsInPassengerDecks
}

export const maxWarningIconsInRow = (data: Inventory): number => {
  const { carDecksAvailabilities: carDecks, passengerDecksAvailabilities: passengerDecks } = data

  const inventoryArray = Array.from(new Set([...Object.keys(carDecks), ...Object.keys(passengerDecks)])).map(
    (sailRefId) => {
      const carDeck = carDecks[Number(sailRefId)] || null
      const passengerDeck = passengerDecks[Number(sailRefId)] || null

      return {
        sailRefId: Number(sailRefId),
        carDeck,
        passengerDeck,
      }
    }
  )

  const warningCounts = inventoryArray.map((item) => {
    const status = item.carDeck?.status ?? item.passengerDeck?.status
    return [
      item.carDeck?.dangerousGoods || item.passengerDeck?.dangerousGoods,
      R.equals(status, 'CANCELLED'),
      //  item.carDeck?.noHeavyTracks, // TODO: No field now, add field
    ].filter(Boolean).length
  })

  return Math.max(0, ...warningCounts)
}

export const hasBicycleAvailability = (inventory: Inventory): boolean =>
  Object.values(inventory.carDecksAvailabilities).some((avail) =>
    avail.availableInventoryClasses.some((item) => item.inventoryClass === 'BICYCLE')
  ) ||
  Object.values(inventory.passengerDecksAvailabilities).some((avail) =>
    avail.availableInventoryClasses.some((item) => item.inventoryClass === 'BICYCLE')
  )
