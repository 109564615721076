import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { useTravelDurationMessage } from '@/root/shared-hooks'
import { RouteInfoCardProps } from '@/root/shared-types'
import { BottomArrowIcon } from '@/root/ui/shared/icons/BottomArrowIcon'
import { VesselIcon } from '@/root/ui/shared/icons/VesselIcon'
import { BidirectionIcon } from '@/root/ui/shared/icons/BidirectionIcon'
import { CancelIcon } from '@/root/ui/shared/icons/CancelIcon'
import cn from 'classnames'

export const RouteInfoCard: FC<RouteInfoCardProps> = ({
  routeInfo,
  showCancelButton = false,
  showSwapPorts = true,
  setHideRoundTripButton,
  swapPorts,
}) => {
  const { getTranslation } = useGetTranslations()

  const { departurePort, arrivalPort, shipName, duration } = routeInfo
  const travelDurationMessage = useTravelDurationMessage(duration)

  return (
    <div
      data-testid="trip-header"
      className={cn('flex w-full justify-between rounded-md bg-white p-4 font-bold shadow-sm', {
        'min-h-48': !departurePort || arrivalPort,
        'min-h-32': departurePort && !arrivalPort,
      })}
    >
      <div className="flex flex-col items-start justify-between">
        <p className="text-primary-base text-large leading-9">{departurePort}</p>
        <div className="flex items-center">
          {Boolean(arrivalPort) && <BottomArrowIcon />}
          <p className="text-base-normal-lighter ms-3 whitespace-pre-line text-sm xxs:text-base">
            {travelDurationMessage}
          </p>
        </div>
        <p className="text-primary-base text-large leading-9">{arrivalPort}</p>
        <div className="flex items-center">
          <VesselIcon color="base-normal-lighter" />
          <p className="text-base-normal-lighter ms-3 text-xs uppercase">{shipName}</p>
        </div>
      </div>

      <div className="flex flex-col justify-between">
        {showSwapPorts && (
          <button
            onClick={swapPorts}
            data-testid="change-direction-button"
            className="flex w-16 flex-col items-center gap-1.5"
          >
            <BidirectionIcon width={32} height={32} className="h-8" />
            <p className="text-secondary text-xs">{getTranslation('changeDirectionLabel')}</p>
          </button>
        )}

        {showCancelButton && (
          <button className="flex flex-col items-center gap-1.5" onClick={() => setHideRoundTripButton(false)}>
            <CancelIcon />
            <p className="text-error text-xs"> {getTranslation('ticketActionButtonsCancel')}</p>
          </button>
        )}
      </div>
    </div>
  )
}
