import { DateTime } from 'luxon'

interface TimezoneAndInitialTimeParams {
  defaultTimezone: string
  initialTime?: DateTime
}

export const getReferenceTime = ({ defaultTimezone, initialTime }: TimezoneAndInitialTimeParams): DateTime => {
  const now = DateTime.now()
  const nowInDefaultTimezone = now.setZone(defaultTimezone)

  const differenceInMinutes = nowInDefaultTimezone.offset - now.offset
  const adjustedNow = now.plus({ minutes: differenceInMinutes })

  return initialTime?.isValid && initialTime > adjustedNow ? initialTime : adjustedNow
}
