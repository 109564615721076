import { regexPatterns } from '@/root/shared-consts'
import { LocalizedLocation } from '@/root/shared-types'

const { separatorDash } = regexPatterns

export const reverseTitleParts = (obj: LocalizedLocation): LocalizedLocation => {
  return Object.keys(obj).reduce((acc, key) => {
    const localizedKey = key as keyof LocalizedLocation
    const str = obj[localizedKey]

    const parts = str.split(separatorDash).map((part) => part.trim())

    if (parts.length === 2 && separatorDash.test(str)) {
      acc[localizedKey] = `${parts[1]} - ${parts[0]}`
    } else {
      acc[localizedKey] = str
    }

    return acc
  }, {} as LocalizedLocation)
}
