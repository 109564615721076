import { ParsedUrlQuery } from 'node:querystring'
import * as R from 'ramda'

export interface SailParam {
  code: string
  sailDates: string[]
}

export const parseSailPackages = (query: ParsedUrlQuery): Array<SailParam> => {
  let packages: string[] = []
  let sailDates: string[] = []
  if ('packages' in query && typeof query.packages === 'string') {
    packages = query.packages?.split(',')
  }
  if ('dates' in query && typeof query.dates === 'string') {
    sailDates = query.dates?.split(',')
  }
  return R.zipWith((p, s) => ({ code: p, sailDates: [s] }), packages, sailDates)
}
