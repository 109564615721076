import type { Dispatch } from 'react'
import * as R from 'ramda'
import {
  AddCartItem,
  BookingCartState,
  CartItem,
  defaultState,
  GuestSlot,
  ItemClass,
  VehicleSlot,
} from '@/root/shared-providers/BookingCart/types'

export enum Action {
  addToCart,
  removeFromCart,
  clearCart,
  updateTotalPrice,
  setReservationId,
  resetCart,
  setTotalPrice,
  setReservationToken,
}

export interface AddAction {
  type: Action.addToCart
  payload: Omit<AddCartItem, 'seqNum'> & { itemClass: ItemClass }
}

export interface RemAction {
  type: Action.removeFromCart
  payload: Pick<CartItem, 'priceCategory'> & { itemClass: ItemClass }
}

export interface ClearAction {
  type: Action.clearCart
}

export interface UpdatePriceAction {
  type: Action.updateTotalPrice
  payload: string
}

export interface SetStartedAction {
  type: Action.setReservationId
  payload: number
}

export interface ResetAction {
  type: Action.resetCart
  payload: never
}

export interface SetTotalPriceAction {
  type: Action.setTotalPrice
  payload: string
}

export interface SetTokenAction {
  type: Action.setReservationToken
  payload: string
}

export type CartAction =
  | AddAction
  | RemAction
  | ClearAction
  | UpdatePriceAction
  | SetStartedAction
  | ResetAction
  | SetTotalPriceAction
  | SetTokenAction

export interface BookingCartStateContextProps {
  state: BookingCartState
  dispatch: Dispatch<CartAction>
}

export const neverError = (message: string, token: never): void => {
  throw new Error(`${message} : ${token} should not exist`)
}

const calcLocalPrice = R.compose(
  R.toString,
  R.sum,
  R.map<CartItem, number>((item) => item.price * item.quantity)
)
const sortByPriceCode = R.sortBy(R.prop('priceCategory'))

const getUpdatedItemsListPieceOfState = (itemKey: ItemClass) => (state: BookingCartState, item: AddCartItem) => {
  const others = state[itemKey].filter(({ priceCategory }) => priceCategory !== item.priceCategory)
  const [existingItem] = state[itemKey].filter(({ priceCategory }) => priceCategory === item.priceCategory)
  const guests: Array<GuestSlot | VehicleSlot> = [{ seqNum: state.nextSeqNum + 1, ...item }]
  let { nextSeqNum } = state

  // todo: addons, bikes should also go here after implementation
  const otherTypesItems = state[itemKey === 'passengers' ? 'vehicles' : 'passengers']

  let newCartItem: CartItem = { ...item, slots: guests }

  if (existingItem) {
    existingItem.quantity = existingItem.quantity + item.quantity
    existingItem.slots = R.take(existingItem.quantity, [...existingItem.slots, ...guests])
    newCartItem = existingItem
    if (existingItem.quantity <= 0) {
      return getItemsListWithoutPieceOfState(itemKey)(state, item.priceCategory)
    }
  }

  nextSeqNum += 1
  const newItems = sortByPriceCode([...others, newCartItem])
  return {
    [itemKey]: newItems,
    nextSeqNum,
    totalPrice: calcLocalPrice([...newItems, ...otherTypesItems]),
  }
}

const getItemsListWithoutPieceOfState = (itemKey: ItemClass) => (state: BookingCartState, currentCode: string) => {
  const itemsWithoutCurrent = state[itemKey].filter(({ priceCategory }) => priceCategory !== currentCode)
  // todo: addons, bikes should also go here after implementation
  const itemsOfOtherTypes = state[itemKey === 'passengers' ? 'vehicles' : 'passengers']

  return {
    [itemKey]: sortByPriceCode(itemsWithoutCurrent),
    totalPrice: calcLocalPrice([...itemsWithoutCurrent, ...itemsOfOtherTypes]),
  }
}

export const bookingCartReducer = (state: BookingCartState, action: CartAction): BookingCartState => {
  switch (action.type) {
    case Action.addToCart: {
      return { ...state, ...getUpdatedItemsListPieceOfState(action.payload.itemClass)(state, action.payload) }
    }
    case Action.removeFromCart: {
      return {
        ...state,
        ...getItemsListWithoutPieceOfState(action.payload.itemClass)(state, action.payload.priceCategory),
      }
    }
    case Action.clearCart: {
      return { ...state, ...R.omit(['reservationId'], defaultState) }
    }
    case Action.updateTotalPrice: {
      return { ...state, totalPrice: action.payload }
    }
    case Action.setReservationId: {
      return { ...state, reservationId: action.payload }
    }
    case Action.setReservationToken: {
      return { ...state, reservationToken: action.payload }
    }
    case Action.setTotalPrice: {
      return { ...state, totalPrice: action.payload }
    }
    case Action.resetCart: {
      return { ...defaultState }
    }
    default:
      neverError('Irrelevant type', action)
      return { ...state }
  }
}
