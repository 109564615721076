import { DateTime } from 'luxon'
import { dateFormats } from '@/root/shared-consts'
import { TravelDuration } from '@/root/shared-types'

const { SIMPLE_TIME } = dateFormats

interface InitialTimestampParams {
  isNextDay: boolean
  startDay?: Date
  startTime?: string
  delay?: TravelDuration
}

export const getInitialTimestamp = ({ startDay, startTime, delay, isNextDay }: InitialTimestampParams): DateTime => {
  const day =
    startDay && DateTime.fromJSDate(startDay).isValid
      ? DateTime.fromJSDate(startDay).plus({ days: Number(isNextDay) })
      : DateTime.now()

  const time =
    startTime && DateTime.fromFormat(startTime, SIMPLE_TIME).isValid
      ? DateTime.fromFormat(startTime, SIMPLE_TIME)
      : day.set({ hour: 0, minute: 0 })

  return day
    .set({ hour: time.hour, minute: time.minute, second: 0, millisecond: 0 })
    .plus({ hours: delay?.hours ?? 0, minutes: delay?.minutes ?? 0 })
}
