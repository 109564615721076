import { colorThresholds } from '@/root/shared-consts'
import * as R from 'ramda'

const { GREEN_PERCENT, YELLOW_PERCENT } = colorThresholds

export const getTextColorByThreshold = (value = 0, max = 0) => {
  if (max === 0) {
    return 'text-error-medium-light'
  }

  const percent = Math.round((value / max) * 100)

  return R.cond([
    [R.gte(R.__, GREEN_PERCENT), R.always('text-success-medium-lighter')],
    [R.gte(R.__, YELLOW_PERCENT), R.always('text-warning-medium-lighter')],
    [R.T, R.always('text-error-medium-light')],
  ])(percent)
}
