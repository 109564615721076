import { FC } from 'react'
import { BackIcon } from '@/root/ui/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'
import Link from 'next/link'

export const GoBackButton: FC = () => {
  const { getTranslation } = useGetTranslations()

  return (
    <div data-testid="go-to-prev" className="flex h-16 w-full max-w-96 gap-5">
      <Link
        href="/"
        data-testid="go-to-prev-button-wrapper"
        className="border-neutral-dark bg-neutral-lightest text-neutral-dark flex h-16 w-full max-w-96 items-center justify-center gap-5 rounded-lg border text-sm font-bold uppercase"
        dir="ltr"
      >
        <BackIcon color="neutral-dark" />
        <span>{getTranslation('goBackChangeRoute')}</span>
      </Link>
    </div>
  )
}
