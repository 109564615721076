export const SET_DATE = 'setDate'
export const SET_AVAILABLE_DATES = 'setAvailableDates'
export const SET_PORTS = 'setPorts'
export const SET_INVENTORY = 'setInventory'
export const SET_NEXT_INVENTORY = 'setNextInventory'
export const SET_SAIL_CONTENT = 'setSailContent'
export const SET_NEXT_SAIL_CONTENT = 'setNextSailContent'
export const SET_LOADING = 'setLoading'
export const SET_REF_ID_AND_TIME = 'setSailRefIdAndTime'
export const SET_HIDE_ROUND_TRIP_BUTTON = 'setHideRoundTripButton'
