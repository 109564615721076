import { FC, PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'
import { BookingProgressBar, BookingProgressBarContainer } from '@/root/modules/shared'
import { parseSailPackages, SelectDateTimeContent } from '@/root/modules/shared/SelectDateTimePage'
import { bookingSteps } from '@/root/shared-consts'
import {
  useCustomRouter,
  useGetSailPackage,
  useAllAndSelectedPackage,
  useGetTranslations,
  useErrorBanner,
  useConfig,
} from '@/root/shared-hooks'
import { SailPackage } from '@/root/shared-types'

interface BannerProps {
  variant: string
  title?: string
  body?: React.ReactNode
  className?: string
}

export const SelectDateTimePage: FC<PropsWithChildren<{ sailPackages?: SailPackage[] }>> = ({ children }) => {
  const { getTranslation } = useGetTranslations()
  const { theme } = useConfig()
  const { sailPackage: packageFromContext } = useGetSailPackage()
  const { locale, query } = useCustomRouter()
  const sailParams = parseSailPackages(query)

  const { packagesData } = useAllAndSelectedPackage({ locale, code: sailParams[0]?.code })

  const routeBaseInfo = packageFromContext ?? packagesData.packageById

  const { errorBody } = useErrorBanner()

  const ThemedBanner = dynamic<BannerProps>(
    () => import(`@/root/ui/${theme === 'admaritime' ? 'admaritime' : 'bluewave'}/Banner/Banner`)
  )

  const bannerVariant = theme === 'admaritime' ? 'warning' : 'error'

  return (
    <div className="mx-auto my-10 flex max-w-screen-xl flex-col bg-white shadow-complex">
      <BookingProgressBarContainer>
        <BookingProgressBar maxReachedStep={bookingSteps.SELECT_DATE_TIME} />
      </BookingProgressBarContainer>

      {errorBody && (
        <ThemedBanner
          variant={bannerVariant}
          className="rounded-none border-none [&>h2]:uppercase"
          title={getTranslation('bookingProcessErrorTitle')}
          body={errorBody}
        />
      )}

      <SelectDateTimeContent routeBaseInfo={routeBaseInfo} sailParams={sailParams} />
      {children}
    </div>
  )
}
